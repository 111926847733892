<template>
  <div class="see-dia">
    <el-dialog
      :title="'添加黑名单' + addText + '——' + companyName"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @close="close"
    >
      <div style="margin-bottom: 20px;">
        <div
          class="item-id"
          v-for="(item, index) in storeOrStaffIds"
          :key="index"
        >
          <span class="lable">{{ index + 1 }}.{{ addText }}ID：</span>
          <el-input
            maxlength="20"
            size="small"
            :placeholder="'请输入外部' + addText + 'ID'"
            v-model="storeOrStaffIds[index]"
            clearable
            @input="validateInput(index)"
          >
          </el-input>
          <i
            v-if="index == 0"
            class="el-icon-circle-plus-outline exist_icon"
            @click="createAdd"
          />
          <i
            v-if="index > 0"
            class="el-icon-remove-outline exist_icon exist_icon_del"
            @click="delAdd(index)"
          ></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">取消</el-button>
        <el-button type="primary" @click.native="save">保存并生效</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="操作提示"
      :visible.sync="dialogSureVisible"
      :close-on-click-modal="false"
      width="550px"
      @closed="rowId = ''"
    >
      <div>
        <p class="dialog-tip" style="height:40px;">
          说明：保存后，该外部商户下单若触发了所添加黑名单，则默认不向回收商推送报价，系统自动推送极低价
        </p>
        <p style="text-align:center;height:40px;font-size: 16px;">
          是否确认添加<span style="color: #0981FF;"
            >{{ storeOrStaffIds.length }}个</span
          >黑名单{{ addText }}？
        </p>
        <el-form style="margin-top:10px;">
          <el-form-item label="动态口令" size="medium" label-width="100px">
            <el-input
              v-model="command"
              placeholder="请输入谷歌动态验证码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogSureVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="sureBtnLoading"
          @click.native="sureHandleSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    riskType: {
      type: String,
      default: "",
    },
    companyName: {
      type: String,
      default: "",
    },
    companyId: [String, Number],
  },
  data() {
    return {
      addText: "",
      sureBtnLoading: false,
      dialogSureVisible: false,
      addNum: 0,
      command: "",
      storeOrStaffIds: [""],
    };
  },
  created() {
    this.addText =
      this.riskType == "00" ? "门店" : this.riskType == "01" ? "店员" : "";
  },
  methods: {
    close() {
      this.$emit("close");
    },
    validateInput(index) {
      this.storeOrStaffIds[index] = this.storeOrStaffIds[index].replace(
        /[^a-zA-Z0-9]/g,
        ""
      );
    },
    createAdd() {
      this.storeOrStaffIds.push("");
    },
    delAdd(index) {
      this.storeOrStaffIds.splice(index, 1);
    },
    save() {
      let flag = false;
      this.storeOrStaffIds.forEach((item) => {
        if (item.length === 0) {
          flag = true;
        }
      });
      if (flag) {
        this.$message.error("请补全所有ID");
        return;
      }
      this.dialogSureVisible = true;
    },
    sureHandleSuccess() {
      if(!this.command){
        this.$message.error("请输入动态口令");
        return
      }
      let params = {
        companyId: this.companyId,
        riskType: this.riskType,
        command: this.command,
        storeOrStaffIds: this.storeOrStaffIds,
      };
      this.sureBtnLoading = true;
      _api
        .addBlackMsgList(params)
        .then((res) => {
          if (res.code === 1) {
            this.$message.success("添加成功");
            this.close()
            this.$emit("addSuccess");
          }
        })
        .finally(() => {
          this.sureBtnLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.see-dia {
  .dialog-tip {
    font-size: 14px;
    color: #ff8080;
  }
  .item-id {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .lable {
      min-width: 85px;
    }
  }
  .exist_icon {
    color: #0981ff;
    font-size: 23px;
    cursor: pointer;
    display: inline-block;
    //transform: translateY(3px);
    padding-left: 8px;
  }
  .exist_icon_del {
    color: #f76453;
    transform: translateY(0.1px);
  }
}
</style>
