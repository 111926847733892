<template>
  <div class="see-dia">
    <el-dialog
      :title="'黑名单' + addText + '——' + companyName"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      @close="close"
    >
      <GlobalTable
        v-loading="tableLoading"
        v-if="dialogVisible"
        :maxHeight="550"
        :columns="tableColumns"
        :data="tableData"
        :isPagination="false"
      >
        <el-table-column :label="'外部'+addText+'ID'" slot="riskId" align="center">
          <template v-slot="{ row }">
            <span>{{row.riskId}}</span>
          </template>
        </el-table-column>
        <el-table-column label="添加人" slot="contact" align="center">
          <template v-slot="{ row }">
            <span>{{row.contact}}-{{ row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="operate" align="center">
          <template v-slot="{ row }">
            <span style="color: #ff8080;cursor: pointer;" @click="remove(row)">移除</span>
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="操作提示"
      :visible.sync="dialogSureVisible"
      :close-on-click-modal="false"
      width="550px"
      @closed="command = ''"
    >
      <div class="addparto">
        说明：移除后，该{{addText}}不再被标记为黑名单，下单时可正常推送给回收商报价
      </div>
      <div
        style="font-size: 18px;color: #666666;text-align: center;height: 60px;line-height: 50px;"
      >
        是否确认移除黑名单{{addText}}？
      </div>
      <el-form style="margin-top:10px;">
          <el-form-item label="动态口令" size="medium" label-width="100px">
            <el-input
              v-model="command"
              placeholder="请输入谷歌动态验证码"
            ></el-input>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogSureVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="sureBtnLoading"
          @click.native="sureHandleSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    riskType: {
      type: String,
      default: "",
    },
    companyName: {
      type: String,
      default: "",
    },
    companyId: [String, Number],
  },
  data() {
    return {
      addText: "",
      tableData: [],
      tableLoading: false,
      tableColumns: [
        { slotName: "riskId" },
        { slotName: "contact" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "operate" },
      ],
      sureBtnLoading: false,
      dialogSureVisible: false,
      riskId: "",
      command: "",
      isRemove: false,
    };
  },
  created() {
    this.addText =
      this.riskType == "00" ? "门店" : this.riskType == "01" ? "店员" : "";
    this.getDataList();
  },
  methods: {
    close() {
      this.$emit("close",this.isRemove);
    },
    getDataList() {
      let params = {
        riskType: this.riskType,
        companyId: this.companyId,
      };
      this.tableLoading = true;
      _api
        .riskValueList(params)
        .then((res) => {
          if (res.code === 1) {
            this.tableData = res.data || [];
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    remove(row) {
      console.log(row);
      this.riskId = row.riskId;
      this.dialogSureVisible = true;
    },
    sureHandleSuccess() {
      if(!this.command){
        this.$message.error("请输入动态口令");
        return
      }
      let params = {
        command: this.command,
        companyId: this.companyId,
        riskId: this.riskId,
        riskType: this.riskType,
      }
      this.sureBtnLoading = true
      _api.deleteRiskValue(params).then(res => {
        if(res.code === 1){
          this.$message.success("操作成功")
          this.dialogSureVisible = false
          this.getDataList()
          this.isRemove = true
        }
      }).finally(() => {
          this.sureBtnLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.see-dia {
  .addparto {
    font-size: 14px;
    color: #ff8080;
  }
}
</style>
