<template>
  <div class="add-company">
    <el-dialog
      title="添加商户"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="900px"
      top="3%"
      @close="close"
    >
      <div
        class="addparto"
        style="display: flex; flex-direction: row; align-items: center"
      >
        <img
          style="width: 14px; height: 14px; margin-right: 5px"
          src="@/assets/images/ico_ts_red (2).png"
        />
        说明：目前仅外部商户可进行黑名单管理。
      </div>
      <el-form
        :inline="true"
        class="demo-form-inline"
        size="small"
        style="margin-top: 13px"
      >
        <el-form-item label="门店商家" label-width="80px">
          <el-select
            v-model="companyId"
            placeholder="请输入选择门店商户名称查询"
            filterable
            clearable
          >
            <el-option
              v-for="item in companyList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleCurrentChange(1)"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <GlobalTable
        v-loading="tableLoading"
        v-if="dialogVisible"
        :columns="tableColumns"
        :currentPage="page.pageNum"
        :total="page.total"
        :pageSize="page.pageSize"
        :isIndex="false"
        :data="companyTableData"
        @handleCurrentChange="handleCurrentChange"
      >
        <el-table-column
          label="单选"
          slot="radioSelect"
          align="center"
          width="60"
        >
          <template slot-scope="{ row }">
            <el-radio
              v-model="currentRowId"
              size="medium"
              :label="row.companyId"
              >空</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column label="门店商名称" slot="companyName" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.companyName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.companyName }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="地址" slot="address" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.address"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.address }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
      </GlobalTable>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="close">取消</el-button>
        <el-button
          type="primary"
          :loading="sureBtnLoading"
          @click.native="sureClick"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companyId: "",
      companyList: [],
      companyTableData: [],
      tableLoading: false,
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableColumns: [
        { slotName: "radioSelect" },
        { slotName: "companyName" },
        { label: "联系人", prop: "contact" },
        { label: "电话", prop: "phone" },
        { slotName: "address" },
        { label: "入住时间", prop: "createTime" },
      ],
      sureBtnLoading: false,
      currentRowId: "",
    };
  },
  created() {
    this.getCompanyList();
    this.handleCurrentChange();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      let params = {
        companyId: this.companyId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.tableLoading = true;
      _api
        .externalMerchantList(params)
        .then((res) => {
          if (res.code === 1) {
            this.companyTableData = res.data.records;
            this.page.total = res.data.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getCompanyList() {
      _api.selectOutCompanyList().then((res) => {
        if (res.code === 1) {
          this.companyList = res.data;
        }
      });
    },
    sureClick() {
      if(!this.currentRowId){
        return this.$message.error("请选择要添加的商户");
      }
      this.sureBtnLoading = true;
      _api
        .addExternalMerchantBlack({ companyIds: this.currentRowId?[this.currentRowId]:[] })
        .then((res) => {
          if (res.code === 1) {
            this.$message.success("添加成功");
            this.close()
            this.$emit("addSuccess");
          }
        })
        .finally(() => {
          this.sureBtnLoading = false;
        });
    },
    changeRedio() {},
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.add-company {
  .addparto {
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff8080;
  }
  .nowrap-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  /deep/.el-radio__label {
    display: none;
  }
}
</style>
