<template>
  <div class="black-list">
    <GlobalInfoBar
      title="黑名单门店管理"
      content="可添加编辑黑名单门店商家"
    ></GlobalInfoBar>
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <GlobalForm
          :init-data="initData"
          :form-item-list="formItemList"
          :inline="true"
          :labelWidth="100"
          round
          :needBtnLoading="true"
          @handleConfirm="handleConfirm"
          confirmBtnName="查询"
        >
          <el-form-item label="门店商户" slot="companyId" label-width="80px">
            <el-select
              v-model="companyId"
              placeholder="请输入选择门店商户名称查询"
              filterable
              clearable
            >
              <el-option
                v-for="item in companyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="外部门店ID" slot="storeId" label-width="90px">
            <el-input placeholder="请输入ID查询" v-model="storeId" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="添加时间" slot="entryTime" label-width="70px">
            <el-date-picker
              v-model="entryTime"
              type="daterange"
              start-placeholder="开始日期"
              range-separator="~"
              end-placeholder="结束日期"
              clearable
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <div style="text-align: right; margin-bottom: 20px" slot="AddSlot">
            <el-button
              icon="el-icon-plus"
              type="primary"
              @click="openAddCompanyDia"
              >添加商户</el-button
            >
          </div>
        </GlobalForm>
      </div>
      <div class="table-box">
        <GlobalTable
          ref="GlobalTable"
          v-loading="loading"
          :columns="tableColumns"
          :data="dataList"
          :isPagination="false"
        >
          <el-table-column label="商户名称" slot="companyName" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.companyName"
                placement="top"
              >
                <div class="nowrap-ellipsis">
                  {{ row.companyName }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="黑名单限制规则"
            slot="restrictionRule"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.restrictionRule"
                placement="top"
              >
                <div class="nowrap-ellipsis">
                  {{ row.restrictionRule }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="黑名单门店" slot="countStore" align="center">
            <template slot-scope="{ row }">
              <span class="txt-underline" @click="openSeeDia(row,'00')">{{
                row.countStore || 0
              }}</span>
              <div
                class="txt-underline"
                style="color:#e6a23c;"
                @click="openAddDia(row,'00')"
              >
                添加
              </div>
            </template>
          </el-table-column>
          <el-table-column label="黑名单店员" slot="countStaff" align="center">
            <template slot-scope="{ row }">
              <span class="txt-underline" @click="openSeeDia(row,'01')">{{
                row.countStaff || 0
              }}</span>
              <div
                class="txt-underline"
                style="color:#e6a23c;"
                @click="openAddDia(row,'01')"
              >
                添加
              </div>
            </template>
          </el-table-column>
          <el-table-column label="添加人" slot="userName" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.userName }} - {{ row.userPhone }}</span>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </GlobalChunk>
    <AddCompanyDia
      v-if="addCompanyDiaShow"
      :dialogVisible="addCompanyDiaShow"
      @close="closeAddCompanyDia"
      @addSuccess="handleConfirm"
    />
    <SeeDia
      v-if="seeDiaShow"
      :dialogVisible="seeDiaShow"
      :companyId="rowCompanyId"
      :riskType="riskType"
      :companyName="rowCompanyName"
      @close="closeSeeDia"
    />
    <AddDia
      v-if="addDiaShow"
      :dialogVisible="addDiaShow"
      :companyId="rowCompanyId"
      :riskType="riskType"
      :companyName="rowCompanyName"
      @close="closeAddDia"
      @addSuccess="handleConfirm"
    />
  </div>
</template>

<script>
import _api from "@/utils/request";
import AddCompanyDia from "./dialog/addCompanyDia/index.vue";
import SeeDia from "./dialog/seeDia/idnex.vue";
import AddDia from "./dialog/addDia/index.vue";
import moment from "moment";
export default {
  components: { AddCompanyDia, SeeDia, AddDia },
  data() {
    return {
      loading: false,
      initData: null,
      formItemList: [
        { slotName: "companyId" },
        { slotName: "storeId" },
        { slotName: "entryTime" },
      ],
      SeachParams: {},
      companyList: [],
      entryTime: [
        moment()
          .day(moment().day() - 30)
          .format("YYYY-MM-DD 00:00:00"),
        moment().format("YYYY-MM-DD 23:59:59"),
      ],
      companyId: "",
      storeId: "",
      dataList: [],
      tableColumns: [
        { slotName: "companyName" },
        { label: "所属外部渠道", prop: "channelName" },
        { slotName: "restrictionRule" },
        { slotName: "countStore" },
        { slotName: "countStaff" },
        { slotName: "userName" },
        { label: "添加时间", prop: "createTime" },
      ],
      addCompanyDiaShow: false,
      rowCompanyId: "",
      rowCompanyName: "",
      seeDiaShow: false,
      addDiaShow: false,
      riskType: "",
    };
  },
  created() {
    this.getCompanyList();
    this.handleConfirm();
  },
  mounted() {},
  methods: {
    handleConfirm(data, cd) {
      let startTime = "";
      let endTime = "";
      if (this.entryTime === null) {
        startTime = "";
        endTime = "";
      } else {
        startTime = moment(this.entryTime[0]).format("x");
        endTime = moment(this.entryTime[1]).format("x");
      }
      this.SeachParams = {
        companyId: this.companyId,
        storeId: this.storeId,
        startTime: startTime,
        endTime: endTime,
        disuseLoding: () => {
          if (typeof cd === "function") {
            cd();
          }
        },
      };
      this.loading = true;
      _api
        .blackExternalMerchantList(this.SeachParams)
        .then((res) => {
          if (res.code === 1) {
            this.dataList = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
          if (typeof cd === "function") {
            cd();
          }
        });
    },
    getCompanyList() {
      _api.selectOutCompanyList().then((res) => {
        if (res.code === 1) {
          this.companyList = res.data;
        }
      });
    },
    openAddCompanyDia() {
      this.addCompanyDiaShow = true;
    },
    closeAddCompanyDia() {
      this.addCompanyDiaShow = false;
    },
    closeSeeDia(isRemove) {
      this.rowCompanyId = ""
      this.rowCompanyName = ""
      this.seeDiaShow = false;
      if(isRemove){
        this.handleConfirm()
      }
    },
    closeAddDia() {
      this.rowCompanyId = ""
      this.rowCompanyName = ""
      this.addDiaShow = false;
    },
    openSeeDia(row,type) {
      this.rowCompanyId = row.companyId
      this.rowCompanyName = row.companyName
      this.riskType = type
      this.seeDiaShow = true
    },
    openAddDia(row,type) {
      this.rowCompanyId = row.companyId
      this.rowCompanyName = row.companyName
      this.riskType = type
      this.addDiaShow = true
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.black-list {
  .flex {
    display: flex;
    align-items: center;
  }
  .red-tip {
    color: #ff687b;
    font-size: 14px;
    line-height: 14px;
  }
  .txt-underline {
    color: #0981ff;
    text-decoration: underline;
    cursor: pointer;
  }
  .nowrap-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
</style>
